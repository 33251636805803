'use client';
import space from '@haaretz/l-space.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import React from 'react';
import s9 from 'style9';

// `c` is short for `classNames`
const c = s9.create({
  fullHeight: {
    height: '100%',
  },
  aside: {
    position: 'relative',
    paddingTop: space(3),
  },
  sticky: {
    position: 'sticky',
    top: space(4),
  },
  observerWrapper: {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    width: '1px',
    height: '100%',
  },
  milestone: {
    width: '1px',
    position: 'absolute',
    pointerEvents: 'none',
    userSelect: 'none',
    touchAction: 'none',
  },
});

type SideBoxProviderType = { activeIndex: number } | undefined;

export const SideBoxContext = React.createContext<SideBoxProviderType>(undefined);

export default function useSideBox() {
  const context = React.useContext(SideBoxContext);

  if (context === undefined) {
    throw new Error('useSideBox must be used within a SideBoxContext');
  }

  return context;
}

interface SideBoxContextProviderProps {
  durationOffsets: number[];
  displayDurations: number[];
}

const SIDE_BOX_ITEM_IN_VIEW_EVENT = 'side-box-item-in-view-event';

function onEntry(entries: IntersectionObserverEntry[]) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const event = new CustomEvent(SIDE_BOX_ITEM_IN_VIEW_EVENT);

      entry.target.dispatchEvent(event);
    }
  });
}

let intersectionObserver: IntersectionObserver | undefined;

export function SideboxContextProvider({
  children,
  durationOffsets,
  displayDurations,
}: React.PropsWithChildren<SideBoxContextProviderProps>) {
  const asideRef = React.useRef<HTMLDivElement>(null);

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [clientHeightFallback, setClientHeightFallback] = React.useState(0);

  const value = React.useMemo(() => ({ activeIndex }), [activeIndex]);
  const platform = usePlatform();

  const isDesktopPlatform = platform === 'desktop';

  React.useEffect(() => {
    function onVisibilityChange() {
      if (
        document.visibilityState === 'visible' &&
        !clientHeightFallback &&
        document.documentElement.clientHeight > 0
      ) {
        setClientHeightFallback(document.documentElement.clientHeight);
      }
    }

    if (document.visibilityState === 'hidden') {
      document.addEventListener('visibilitychange', onVisibilityChange);

      return () => {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      };
    }

    return undefined;
  }, [clientHeightFallback]);

  React.useEffect(() => {
    function onItemEntry(event: Event) {
      const target = event.target as HTMLElement;

      if (target) {
        const index = target.getAttribute('data-sidebox-idx');

        setActiveIndex(Math.min(+(index ?? 0), displayDurations.length - 1));
      }
    }

    const elem = asideRef.current;

    const clientHeight = document.documentElement.clientHeight || clientHeightFallback;

    if (isDesktopPlatform && elem && clientHeight > 0) {
      if (!intersectionObserver) {
        // NOTE: This sets the `rootMargin` to be one pixel of the viewport,
        // at its vertical middle. We use this so that there can be only a
        // single element intersecting, and that every movement through
        // the boundary triggers the observer
        const rootMarginY = clientHeight / 2 - 0.5;

        intersectionObserver = new IntersectionObserver(onEntry, {
          rootMargin: `-${rootMarginY}px 0px -${rootMarginY}px 0px`,
          threshold: 0,
        });
      }

      const childElements = Array.from(elem.children);

      childElements.forEach(child => {
        child.addEventListener(SIDE_BOX_ITEM_IN_VIEW_EVENT, onItemEntry);
        intersectionObserver?.observe(child);
      });

      return () => {
        childElements.forEach(child => {
          child.removeEventListener(SIDE_BOX_ITEM_IN_VIEW_EVENT, onItemEntry);
          intersectionObserver?.unobserve(child);
        });
      };
    }

    return () => {
      intersectionObserver?.disconnect();
      intersectionObserver = undefined;
    };
  }, [asideRef, clientHeightFallback, displayDurations.length, isDesktopPlatform]);

  if (!isDesktopPlatform) return null;

  return (
    <aside className={`${s9(c.aside, c.fullHeight)} no-print`}>
      <div className={s9(c.sticky)}>
        <SideBoxContext.Provider value={value}>{children}</SideBoxContext.Provider>
      </div>
      <div className={s9(c.observerWrapper)} ref={asideRef}>
        {durationOffsets.map((offset, index) => (
          <div
            key={offset}
            data-sidebox-idx={index}
            style={{
              height: `${displayDurations[index]}px`,
              top: `${durationOffsets[index] || 0}px`,
            }}
            className={s9(c.milestone)}
          />
        ))}
      </div>
    </aside>
  );
}
