import type { AdSlotFragment } from '@haaretz/s-fragments/AdSlot';
import type { ListFragment } from '@haaretz/s-fragments/List';

const litsTypename: ListFragment['__typename'] = 'htz_list_List';
const adSlotTypename: AdSlotFragment['__typename'] = 'AdSlot';

export default function isSideBoxItemElement(
  item:
    | {
        __typename?: string;
      }
    | undefined
    | null
): item is AdSlotFragment | ListFragment {
  if (!(item instanceof Object)) return false;

  return item.__typename === litsTypename || item.__typename === adSlotTypename;
}
